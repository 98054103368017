<template>
  <ul class="menu-nav">
    <template v-for="(item, key) in getMenus">
      <li
        class="menu-section"
        v-bind:key="item.id"
        @click="toggleExpansion(key)"
      >
        <div class="menu-text">
          <div class="menu-icon-new"><i :class="item.icon"></i></div>
          <div class="menu-name">
            <b>{{ item.name }}</b>
          </div>
        </div>
        <i class="menu-icon" :class="item.icon"></i>
      </li>
      <template v-for="subItem in item.sub">
        <router-link
          :to="subItem.url"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-bind:key="subItem.id"
          exact
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
            v-show="isExpanded(key)"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon" :class="subItem.icon"></i>
              <span class="menu-text"> {{ subItem.name }} </span>
            </a>
          </li>
        </router-link>
      </template>
    </template>
  </ul>
</template>

<script>
import { GET_MENU } from "@/core/services/store/menu.module";
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  data() {
    return {
      expandedGroup: []
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isExpanded(key) {
      return this.expandedGroup.indexOf(key) !== -1;
    },
    toggleExpansion(key) {
      if (this.isExpanded(key))
        this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1);
      else this.expandedGroup.push(key);
    }
  },
  created() {
    this.$store.dispatch(GET_MENU, {
      params: { role: this.currentUser.data.role.id }
    });
  },
  computed: {
    ...mapGetters(["currentUser", "currentMenus"]),
    getMenus() {
      return this.currentMenus;
    }
  }
};
</script>

<style>
.menu-text:hover {
  cursor: pointer;
}
.menu-link {
  padding: 10px 10px 10px 55px!important;
}
.menu-icon-new {
  width: 15%;
}
.menu-name {
  width: 85%;
}
.menu-text {
  width: 95% !important;
}
</style>

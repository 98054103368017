<template>
  <div class="topbar-item">
    <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <v-badge color="purple" overlap :content="lastLogSeen">
          <v-icon size="25">mdi-bell</v-icon>
        </v-badge>
      </span>
    </div>
    <!-- begin::Quick Panel -->
    <div
      id="kt_quick_panel"
      ref="kt_quick_panel"
      class="offcanvas offcanvas-right pb-10"
      style="overflow: hidden;"
    >
      <!--begin::Header-->
      <div class="mb-3 p-2 quick-panel-button">
        <v-btn-toggle>
          <v-btn small @click="readCheckedData()">
            Read
          </v-btn>
          <v-btn small @click="readAllUnOpenedData()">
            Read All
          </v-btn>
          <v-btn small v-if="!openAll" @click="openAllMessage(true)">
            Show All
          </v-btn>
          <v-btn small v-if="openAll" @click="openAllMessage(false)">
            Hide Read
          </v-btn>
          <v-btn small id="kt_quick_panel_close">
            Close
          </v-btn>
        </v-btn-toggle>
      </div>
      <div
        class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="true"
            >
              Project
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              DLD
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              DMP
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              MNT
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              LET
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="5"
              data-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              DSP
            </a>
          </li>
        </ul>
        <!-- <div class="offcanvas-close mt-n1 pr-5">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div> -->
      </div>
      <!--end::Header-->

      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab :key="'tabs' + d" v-for="(data, d) in currentLogActivity">
              <perfect-scrollbar
                class="scroll pr-7 mr-n7"
                style="max-height: 95vh; position: relative;"
              >
                <div class="mb-15">
                  <!-- <h5 class="font-weight-bold mb-5">{{ d }} Logs</h5> -->
                  <v-checkbox
                    @change="checkAllData($event, d)"
                    label="Choose All"
                  ></v-checkbox>
                  <div :key="d + i" v-for="(item, i) in data">
                    <!--begin: Item -->
                    <div
                      v-if="!item.opened || openAll"
                      class="d-flex align-items-center rounded p-3 mb-3 notification-card"
                      v-bind:class="`bg-light-` + getAlertType(item.alert_type)"
                    >
                      <v-checkbox
                        hide-details
                        v-model="item.checked"
                        @change="checkData($event, item, d)"
                        class="shrink mr-2 mt-0"
                      ></v-checkbox>
                      <span
                        class="svg-icon mr-5"
                        v-bind:class="`svg-icon-info`"
                      >
                        <v-icon
                          large
                          v-if="!item.opened"
                          class="align-self-center"
                          >mdi-email</v-icon
                        >
                        <v-icon large v-else class="h-50 align-self-center"
                          >mdi-email-open</v-icon
                        >
                      </span>

                      <div
                        class="d-flex flex-column flex-grow-1 mr-2"
                        @click="openMessage(item, d)"
                      >
                        <span
                          class="font-weight-normal text-dark-75 font-size-lg mb-1"
                        >
                          {{ getDateNotification(item.created_at) }}
                        </span>
                        <span class="font-size-md mb-2">
                          Reff ID : {{ item.reff_id }}
                        </span>
                        <span class="font-size-md mb-3 subject">
                          {{ item.subject }}
                        </span>
                      </div>
                    </div>
                    <!--end: Item -->
                  </div>
                </div>
              </perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <div data-app>
      <v-dialog
        :attach="true"
        v-model="notificationDialog"
        transition="dialog-top-transition"
        max-width="70vw"
      >
        <v-card>
          <v-toolbar color="primary" class="dialogSubject" dark>{{
            notificationSubject
          }}</v-toolbar>
          <v-card-text>
            <div class="dialogMessage">
              <pre>{{ notificationMessage }}</pre>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end text-right">
            <v-spacer></v-spacer>
            <v-btn color="danger" text @click="notificationDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import { mapGetters } from "vuex";
import {
  GET_LOGS_ACTIVITY,
  GET_LAST_LOG_SEEN_ACTIVITY,
  ADD_NEW_LOG_ACTIVITY,
  UPDATE_LAST_LOG_SEEN_ACTIVITY,
  UPDATE_LOG_ACTIVITY,
  UPDATE_LOG_ACTIVITY_READ_ALL,
  UPDATE_LOG_ACTIVITY_READ_CHECKED
} from "@/core/services/store/logs.module";
import Pusher from "pusher-js";
import { LOGOUT } from "@/core/services/store/auth.module";
import { showToast } from "@/core/helper/toast.helper";
export default {
  name: "KTQuickPanel",
  props: ["pusherStatus"],
  data() {
    return {
      tabIndex: 0,
      notifCounter: 0,
      newestId: 0,
      openAll: false,
      notificationDialog: false,
      notificationSubject: "",
      notificationMessage: "",
      listCheck: [],
      checkAll: false,
      pusher: new Pusher(process.env.VUE_APP_PUSHER_ID, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER
      }),
      pusher2: new Pusher(process.env.VUE_APP_PUSHER_ID, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER
      })
    };
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
    setTimeout(() => {
      this.$store
        .dispatch(GET_LOGS_ACTIVITY, 100)
        .then(data => {
          this.getNewestId(data.data);
        })
        .then(() => {
          var dataNotificationToPic =
            "notification-" + this.currentUserDetail.pic_code;
          var pusher = this.pusher;
          var channel = pusher.subscribe(dataNotificationToPic);
          channel.bind("notification-event", data => {
            this.$store.dispatch(ADD_NEW_LOG_ACTIVITY, data["message"]);
            showToast(
              data.message.subject,
              data.message.message,
              this.getAlertType(data.message.alert_type)
            );
            this.newestId += 1;
          });

          var pusher2 = this.pusher2;
          var dataNotification = "notification-";
          var channel2 = pusher2.subscribe(dataNotification);
          channel2.bind("notification-event", data => {
            this.$store.dispatch(ADD_NEW_LOG_ACTIVITY, data["message"]);
            showToast(
              data.message.subject,
              data.message.message,
              this.getAlertType(data.message.alert_type),
              {
                position: "bottom-end"
              }
            );
            this.newestId += 1;
          });
        });
      this.$store.dispatch(GET_LAST_LOG_SEEN_ACTIVITY);
    }, 1000);
  },
  methods: {
    openMessage(data, logName) {
      this.notificationDialog = true;
      this.notificationSubject = data.subject;
      this.notificationMessage = data.message;
      this.updateLastSeenLog(data, logName);
    },
    getAlertType(value) {
      var data = "";
      switch (value) {
        case "create":
          data = "info";
          break;
        case "update":
          data = "warning";
          break;
        case "delete":
          data = "danger";
          break;
        default:
          data = "secondary";
          break;
      }
      return data;
    },
    getDateNotification(date) {
      var data = new Date(date);
      var newDate =
        data.getDate() +
        "-" +
        (data.getMonth() + 1 < 10
          ? "0" + (data.getMonth() + 1)
          : data.getMonth() + 1) +
        "-" +
        data.getFullYear() +
        " " +
        data.getHours() +
        ":" +
        data.getMinutes() +
        ":" +
        data.getSeconds();
      return newDate;
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    updateNotificationLast() {
      this.$store.dispatch(GET_LOGS_ACTIVITY, 10);
    },
    updateLastSeenLog(data, logName) {
      this.$store.dispatch(UPDATE_LAST_LOG_SEEN_ACTIVITY, data.id);
      if (!data.opened) {
        data.opened = true;
        this.$store.dispatch(UPDATE_LOG_ACTIVITY, {
          data,
          logName
        });
      }
    },
    getNewestId() {
      var newestId = 0;
      for (var d in this.currentLogActivity) {
        var getData = this.currentLogActivity[d];
        for (var z in getData) {
          getData[z].checked = false;
        }
        if (getData.length > 0) {
          var getId = getData[0].id;
          if (newestId < getId) {
            newestId = getId;
          }
        }
      }
      this.newestId = newestId;
      return newestId;
    },
    checkAllData(value, data) {
      var x = this.currentLogActivity[data];
      if (value) {
        x.forEach(z => {
          z.checked = true;
          this.checkAll = true;
        });
      } else {
        x.forEach(z => {
          z.checked = false;
          this.checkAll = false;
        });
      }
      this.$forceUpdate();
      return true;
    },
    openAllMessage(value) {
      if (value) this.openAll = true;
      else this.openAll = false;
    },
    checkData(value, data) {
      if (value) data.checked = true;
      else data.checked = false;
    },
    readCheckedData() {
      //this.updateLastSeenLog(data, logName);
      var dataChecked = [];
      for (var data in this.currentLogActivity) {
        var x = this.currentLogActivity[data];
        x.forEach(z => {
          if (z.checked) {
            dataChecked.push(z.id);
            z.opened = true;
          }
        });
        this.$forceUpdate();
      }
      this.$store.dispatch(UPDATE_LOG_ACTIVITY_READ_CHECKED, dataChecked);
    },
    readAllUnOpenedData() {
      this.$store.dispatch(UPDATE_LOG_ACTIVITY_READ_ALL).then(() => {
        for (var data in this.currentLogActivity) {
          var x = this.currentLogActivity[data];
          x.forEach(z => {
            z.opened = true;
            this.checkAll = true;
          });
          this.$forceUpdate();
        }
      });
    }
  },
  watch: {
    pusherStatus: function(newVal) {
      if (!newVal) {
        this.pusher.disconnect();
        this.pusher2.disconnect();
        this.$store.dispatch(LOGOUT).then(() => {
          window.localStorage.clear();
          this.$router.push({ name: "login" });
        });
      }
    }
  },
  computed: {
    ...mapGetters(["currentLogActivity", "lastLogSeen", "currentUserDetail"])
  }
};
</script>
<style lang="scss">
.dialogMessage {
  margin-top: 1.5em;
}
.purple {
  background-color: purple !important;
}

pre {
  max-height: 50vh;
  font-size: 1.1em;
}

.notification-card {
  cursor: pointer;
  &:hover {
    background-color: #98c6f6 !important;
  }
}

.v-dialog__content {
  z-index: 99999 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.quick-panel-button {
  font-size: 10px;
}
</style>
